/* Color definitions */
:root {
  --font-main: sans-serif;
  --font-header: sans-serif;
  --font-awesome: "Font Awesome 5 Free";
  --color-primary: #FF6600;
  --color-primary-rgb: 255,102,0;
  --color-secondary: #7B16D9;
  --color-secondary-rgb: 123,22,217;
  --color-dark: #1C1B1F;
  --color-dark-rgb: 28,27,31;
  --color-light: #F4F4F4;
  --color-light-rgb: 244,244,244;
  --color-gray: #343337;
  --color-gray-rgb: 52,51,55;
  --color-no-active: #cfcecd;
  --color-no-active-rgb: 207,206,205;
  --color-error: #9f0108;
  --color-error-rgb: 159,1,8;
  --color-header-primary: #3D3B42;
  --color-header-primary-rgb: 61,59,66;
  --color-header-secondary: #706E72;
  --color-header-secondary-rgb: 112,110,114;
  --color-text: #888888;
  --color-text-rgb: 136,136,136;
  --color-text-gray: #D3D3D5;
  --color-text-gray-rgb: 211,211,213;
  --color-table-border: #e3dfdf;
  --color-table-border-rgb: 227,223,223;
  --color-table-light-gray: #F4F4F4;
  --color-table-light-gray-rgb: 244,244,244;
  --color-icon-bg: #D5CFE3;
  --color-icon-bg-rgb: 213,207,227;
  --color-slick-dots-bg: #C4C4C4;
  --color-slick-dots-bg-rgb: 196,196,196;
  --color-slick-arrow: #9D9Ba2;
  --color-slick-arrow-rgb: 61,59,66;
  --w-font-sans: var(--font-main);
}

body .font-gradient {
    background: -webkit-linear-gradient(45deg, var(--color-secondary) 30%, var(--color-primary) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--color-primary);
}

/* Bootstrap overrides */
img.img-circle {
  border-radius: 50%;
}

[data-toggle="collapse"]:not(.collapsed) .fa-angle-double-down:before {
  content: "\f102";
}

[data-bs-toggle="collapse"]:not(.collapsed) .fa-angle-double-down:before {
  content: "\f102";
}

.btn-group-xs>.btn, .btn.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

/* Grid */
.grid .grid-item .grid-item-outer {
  width: 100%;
  position: relative;
}
.grid .grid-item .grid-item-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

/* Wagtail overrides */
.rich-text img {
    max-width: 100%;
    height: auto;
}

.responsive-object {
    position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Carousel Posts */
.popup {
  position: relative; }
  .popup .overlay {
    transition: opacity .25s;
    opacity: .6;
    position: absolute;
    width: 50px;
    top: calc(50% - 25px);
    left: calc(50% - 25px); }
  .popup:hover .overlay {
    opacity: 1; }

.embed-wrapper {
  padding: 32px;
  width: max-content;
  position: relative; }

/* Masthead */
header.masthead {
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #222;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll; }
  @media (min-width: 1024px) {
    header.masthead {
      background-attachment: fixed; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    header.masthead {
      background-attachment: scroll; } }
  header.masthead .masthead-img {
    max-width: 150px;
    max-height: 150px; }
  header.masthead .masthead-title {
    font-size: 4rem;
    line-height: 4rem;
    font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase; }
  header.masthead .masthead-subtitle {
    font-size: 1.5rem;
    line-height: 1.5rem; }
  header.masthead .scroll-down {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center; }
    header.masthead .scroll-down .btn {
      font-size: 30px;
      line-height: 50px;
      width: 50px;
      height: 50px;
      padding: 0;
      letter-spacing: normal;
      color: white;
      border: 2px solid white;
      border-radius: 100% !important;
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s; }
      header.masthead .scroll-down .btn:hover {
        color: rgba(255, 255, 255, 0.5) !important;
        border-color: rgba(255, 255, 255, 0.5);
        outline: none; }
  header.masthead .video-controls {
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-align: right;
    z-index: 1000;
  }
    header.masthead .video-controls .btn {
      font-size: 30px;
      line-height: 50px;
      color: white;
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s;
      height: 50px;
      padding: 0;
      letter-spacing: normal;
    }
      header.masthead .video-controls .btn .fa,
      header.masthead .video-controls .btn .fab {
        border-radius: 100% !important;
        border: 1px solid white;
        width: 25px;
        height: 25px;
        font-size: 16px;
        letter-spacing: normal;
        padding: 3px 0 3px 4px;
        text-align: left;
        vertical-align: middle;
      }
      header.masthead .video-controls .btn:hover {
        color: rgba(255, 255, 255, 0.5) !important;
        outline: none;
      }
        header.masthead .video-controls .btn:hover .fa,
        header.masthead .video-controls .btn:hover .fab {
          border-color: rgba(255, 255, 255, 0.5);
        }
      header.masthead .video-controls .btn .desc {
        font-size: 11px;
        display: inline-block;
        vertical-align: middle;
        width: 100px;
      }
  @media (min-width: 992px) {
    header.masthead {
      height: 100vh;
      min-height: 700px;
      padding-top: 0;
      padding-bottom: 0; }
      header.masthead .masthead-img {
        max-width: 250px;
        max-height: 250px; }
      header.masthead .masthead-title {
        font-size: 5rem;
        line-height: 5rem; }
      header.masthead .masthead-subtitle {
        font-size: 2rem;
        line-height: 2rem; }
      header.masthead .video-controls .btn .fa,
      header.masthead .video-controls .btn .fab {
        border: 2px solid white;
        width: 50px;
        height: 50px;
        font-size: 30px;
        padding: 7px 0 7px 9px; }
  }

header.video {
  background: transparent; }
  header.video .overlay {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: #000; }
  header.video .container,
  header.video .scroll-down {
    z-index: 1; }

@media (min-width: 992px) {
  header.video .overlay {
    opacity: .5; } }

/* Slider */

.tns-outer {
  position: relative; }
  .tns-outer .tns-controls {
    margin-top: 0; }
    .tns-outer .tns-controls button[data-controls="prev"],
    .tns-outer .tns-controls button[data-controls="next"] {
      font-size: 30px !important;
      line-height: 50px;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 40px;
      height: 60px;
      margin: auto 0 !important;
      text-align: center;
      color: white !important;
      border-radius: 0 !important;
      background-color: transparent!important;
      color: rgba(255,255,255,.175)!important;
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s;
      border: 0; }
      @media (min-width: 768px) {
        .tns-outer .tns-controls button[data-controls="prev"],
        .tns-outer .tns-controls button[data-controls="next"] {
          font-size: 50px !important;
          line-height: 90px;
          position: absolute;
          top: 0;
          bottom: 0;
          width: 70px;
          height: 100px;
          z-index: 1000; }}
      .tns-outer .tns-controls button[data-controls="prev"]:hover,
      .tns-outer .tns-controls button[data-controls="next"]:hover {
        background-color: transparent !important;
        color: #ffffff!important; }
    .tns-outer .tns-controls button[data-controls="prev"] {
      left: 15px; }
    .tns-outer .tns-controls button[data-controls="next"] {
      right: 15px; }
    .tns-outer .tns-controls .owl-page span {
      background: rgba(255, 255, 255, 0.5); }
    .tns-outer button[data-action="stop"],
    .tns-outer button[data-action="start"] {
      display: none; }
    .tns-outer button.show-more {
      position: absolute;
      display: block;
      bottom: 1rem;
      right: 4%;
      font-size: .625rem;
      z-index: 2; }
      @media(min-width: 992px) {
        .tns-outer button.show-more {
          font-size: .875rem; } }
      .tns-outer button.show-more:focus {
        outline: 0; }
    .tns-outer .tns-nav {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1; }
      .tns-outer .tns-nav button {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        border: 0;
        padding: 0;
        margin: .4rem;
        background-color: rgba(255,255,255,.25);
        opacity: .75;
        position: relative;
        top: -46px; }
        .tns-outer .tns-nav button:hover {
          opacity: 1; }
        .tns-outer .tns-nav button:focus {
          outline: 0; }
        .tns-outer .tns-nav button.tns-nav-active {
          background-color: #ffffff; }

.carousel {
  position: relative; }
  .carousel.height-100vh {
    padding: 0; }
  .carousel .background {
    width: inherit;
    min-height: 100%;
    z-index: 0;
    position: absolute;
    overflow: hidden;
    background-size: cover;
    background-position: center; }
    .carousel .background div {
      position: absolute;
      overflow: hidden;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  .carousel .item {
    transition: all .725s;
    /*display: table;*/ }
    .carousel.height-100vh .item {
      height: 100vh; }
    .carousel .item > .slide-container {
      padding-top: 4rem;
      transition: all .725s; }
      .carousel.height-100vh .item > .slide-container {
        display: table;
        width: 100%;
        height: 100vh; }
      .carousel .item > .slide-container > .slide-content {
        text-align: center; }
        .carousel.height-100vh .item > .slide-container > .slide-content {
          display: table-cell;
          vertical-align: middle; }
        .carousel .item > .slide-container > .slide-content .block {
          margin: 0 auto; }

  .carousel .item.slidey-slide > .slidey-slide-image.background {
    -webkit-background-size: auto;
    -moz-background-size: auto;
    -o-background-size: auto;
    background-size: auto;
    z-index: 0;
  }

nav.fixed-top + .tns-outer .carousel.height-100vh,
nav.fixed-top + .tns-outer .carousel.height-100vh .item > .slide-container {
  height: calc(100vh - 50px); }
  @media (min-width: 992px) {
    nav.fixed-top + .tns-outer .carousel.height-100vh,
    nav.fixed-top + .tns-outer .carousel.height-100vh .item > .slide-container {
      height: 100vh !important; } }

body > .tns-outer .tns-controls button[data-controls="prev"] {
  left: 0; }

body > .tns-outer .tns-controls button[data-controls="next"] {
  right: 0; }

.intro {
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
    color: #fff;
    background-color: #000;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.intro.slidey-slide > .slidey-slide-image {
    background-size: cover;
    z-index: 0;
}

.intro.intro-full-width { height: 56vw; }
.intro-full-width > .slidey-slide-image {
  background-size: 100vw auto!important;
  max-width: 100vw!important;
}

.intro .intro-body {
    display: table-cell;
    vertical-align: middle;
}

.intro .intro-body .brand-heading {
    font-size: 40px;
}

.intro .intro-body .rich-text p {
    font-size: 18px;
    margin-bottom: 1rem;
}

@media(min-width:768px) {
    .intro {
        height: 100%;
        padding: 0;
    }

    .intro .intro-body .brand-heading {
        font-size: 100px;
    }

    .intro .intro-body .rich-text p {
        font-size: 26px;
    }
}

/*===> Begin product-card <===*/
.product-card {
  position: relative;
  width: 100%;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 55px;
  margin-bottom: 10px;
  box-shadow: 0px 7px 20px rgba(52, 51, 55, 0.2); }
  .product-card.popular .product-card-top {
    padding: 80px 15px 20px 15px; }
  .product-card:hover .product-card-icon, .product-card.popular .product-card-icon {
    background: var(--color-primary);
    border-color: var(--color-primary); }
    .product-card:hover .product-card-icon svg path, .product-card.popular .product-card-icon svg path {
      fill: #fff; }
  .product-card:hover .product-card-content, .product-card.popular .product-card-content {
    background: var(--color-gray); }
    .product-card:hover .product-card-content h4, .product-card.popular .product-card-content h4 {
      color: #fff; }
    .product-card:hover .product-card-content .product-card-description p, .product-card.popular .product-card-content .product-card-description p {
      color: var(--text_gray);
      border-bottom-color: rgba(255, 255, 255, 0.12); }
    .product-card:hover .product-card-content .button, .product-card:hover .product-card-content .button.white, .product-card:hover .product-card-content .btn.white, .product-card.popular .product-card-content .button, .product-card.popular .product-card-content .button.white, .product-card.popular .product-card-content .btn.white {
      color: #fff; }
    .product-card:hover .product-card-content .button:before, .product-card:hover .product-card-content .button.white:before, .product-card:hover .product-card-content .btn.white:before, .product-card.popular .product-card-content .button:before, .product-card.popular .product-card-content .button.white:before, .product-card.popular .product-card-content .btn.white:before {
      background: var(--color-gray) !important; }
  .product-card .product-card-top {
    background-size: cover;
    background-position: center, center; }
  .product-card .product-card-icon {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    width: 100px;
    height: 100px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    background: #fff;
    border-radius: 50%;
    border: 2px solid #F2F2F2;
    box-shadow: 0px 0px 20px rgba(52, 51, 55, 0.1);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: all .3s; }
    .product-card .product-card-icon svg path {
      transition: all .3s; }
  .product-card .product-card-top {
    text-align: center;
    padding: 65px 15px 20px 15px; }
    .product-card .product-card-top p {
      margin-bottom: 0;
      font-family: var(--font-header);
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1em;
      letter-spacing: -0.03em;
      color: #FFFFFF; }
      .product-card .product-card-top p span {
        font-family: var(--font-header);
        font-style: normal;
        font-weight: bold;
        font-size: 68px;
        line-height: 1em;
        letter-spacing: -0.03em;
        color: var(--color-primary); }
  .product-card .product-card-content {
    padding: 45px 15px;
    background: #fff;
    text-align: center;
    transition: all .3s; }
    .product-card .product-card-content h4 {
      margin-bottom: 30px; }
    .product-card .product-card-content h4, .product-card .product-card-content p {
      transition: all .3s; }
    .product-card .product-card-content .product-card-description {
      max-width: 270px;
      margin-left: auto;
      margin-right: auto; }
      .product-card .product-card-content .product-card-description p {
        margin-bottom: 0;
        padding: 10px 0;
        border-bottom: 1px solid rgba(var(--color-header-rgb), 0.3); }
        .product-card .product-card-content .product-card-description p:last-child {
          padding-bottom: 0;
          border-bottom: 0; }
    .product-card .product-card-content .button {
      position: relative;
      z-index: 10; }
  @media (max-width: 991px) {
    .product-card .product-card-top p {
      font-size: 16px; }
      .product-card .product-card-top p span {
        font-size: 50px; }
    .product-card .product-card-content h4 {
      font-size: 18px; }
    .product-card .product-card-content h4, .product-card .product-card-content p {
      transition: all .3s; }
    .product-card .product-card-content .product-card-description {
      max-width: 270px;
      margin-left: auto;
      margin-right: auto; }
      .product-card .product-card-content .product-card-description p {
        margin-bottom: 0;
        padding: 10px 0;
        border-bottom: 1px solid rgba(var(--color-header-rgb), 0.3); }
        .product-card .product-card-content .product-card-description p:last-child {
          padding-bottom: 0;
          border-bottom: 0; }
    .product-card .product-card-content .button {
      position: relative;
      z-index: 10; } }

.products-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  margin-left: -15px;
  margin-right: -15px; }
  .products-block .product-container {
    /*width: 33.33%;*/
    padding-left: 15px;
    padding-right: 15px; }
  @media (max-width: 767px) {
    .products-block .product-container {
      width: 100%; } }

/*===> End product-card <===*/

/*===> Begin gallery-grid <===*/
.gallery-grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .gallery-grid .gallery-grid__item {
    position: relative;
    width: fit-content;
    height: fit-content; }
    .gallery-grid .gallery-grid__item > img {
      width: 100%; }
    .gallery-grid .gallery-grid__item:hover .grid-item-content {
      opacity: 1;
      visibility: visible; }
    .gallery-grid .gallery-grid__item:hover .icon {
      opacity: 0; }
    .gallery-grid .gallery-grid__item .start-video-wrap {
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .gallery-grid .gallery-grid__item .icon {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 10px;
      background: rgba(var(--color-dark-rgb), 0.9);
      transition: all .4s;
      font-size: 21px;
      color:  var(--color-primary); }
  .gallery-grid .grid-item-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    padding: 15px;
    background: rgba(39, 38, 43, 0.9);
    text-align: center;
    transition: all .4s;
    visibility: hidden;
    opacity: 0; }
    .gallery-grid .grid-item-content .grid-item-ico {
      margin-bottom: 10px; }
    .gallery-grid .grid-item-content .grid-item-title {
      margin-bottom: 10px;
      font-family: var(--font-header);
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1em;
      letter-spacing: -0.03em;
      color: #fff; }
    .gallery-grid .grid-item-content .grid-item-info {
      font-weight: 700;
      font-size: 13px;
      color: #fff; }
      .gallery-grid .grid-item-content .grid-item-info span {
        padding: 0 10px; }
  @media (max-width: 991px) {
    .gallery-grid .grid-item-content .grid-item-title {
      font-size: 14px; } }
  @media (max-width: 767px) {
    .gallery-grid .gallery-grid__item {
      width: 33.33%; }
    .gallery-grid .grid-item-content .grid-item-title {
      font-size: 12px; } }
  @media (max-width: 480px) {
    .gallery-grid .gallery-grid__item {
      width: 50%; } }

/*===> End gallery-grid <===*/

/*=-==> Begin masonry-grid <===*/
.masonry-grid > a {
  transition: all 0s; }

/*=-==> End masonry-grid <===*/


/*===> Begin client-filter <===*/
.client-filter,
.masonry-filter {
  position: relative; }
  .client-filter .client-filter-list,
  .client-filter .masonry-filter-list,
  .masonry-filter .client-filter-list,
  .masonry-filter .masonry-filter-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100%;
    margin-bottom: 0;
    padding-left: 0; }
    .client-filter .client-filter-list.white .client-filter-list__item::before,
    .client-filter .client-filter-list.white .masonry-filter-list__item::before,
    .client-filter .masonry-filter-list.white .client-filter-list__item::before,
    .client-filter .masonry-filter-list.white .masonry-filter-list__item::before,
    .masonry-filter .client-filter-list.white .client-filter-list__item::before,
    .masonry-filter .client-filter-list.white .masonry-filter-list__item::before,
    .masonry-filter .masonry-filter-list.white .client-filter-list__item::before,
    .masonry-filter .masonry-filter-list.white .masonry-filter-list__item::before {
      border-top: 1px solid #424048;
      opacity: 1; }
    .client-filter .client-filter-list.white .client-filter-list__item a,
    .client-filter .client-filter-list.white .masonry-filter-list__item a,
    .client-filter .masonry-filter-list.white .client-filter-list__item a,
    .client-filter .masonry-filter-list.white .masonry-filter-list__item a,
    .masonry-filter .client-filter-list.white .client-filter-list__item a,
    .masonry-filter .client-filter-list.white .masonry-filter-list__item a,
    .masonry-filter .masonry-filter-list.white .client-filter-list__item a,
    .masonry-filter .masonry-filter-list.white .masonry-filter-list__item a {
      color: #fff; }
  .client-filter li,
  .masonry-filter li {
    padding-left: 0;
    margin-bottom: 25px;
    list-style: none; }
    .client-filter li::after,
    .masonry-filter li::after {
      display: none; }
  .client-filter .client-filter-list__item,
  .client-filter .masonry-filter-list__item,
  .masonry-filter .client-filter-list__item,
  .masonry-filter .masonry-filter-list__item {
    position: relative;
    margin-right: 90px; }
    .client-filter .client-filter-list__item::before,
    .client-filter .masonry-filter-list__item::before,
    .masonry-filter .client-filter-list__item::before,
    .masonry-filter .masonry-filter-list__item::before {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(100% + 10px);
      width: 70px;
      -ms-transform: translateY(-11px);
      transform: translateY(-11px);
      border-top: 1px solid transparent;
      opacity: .2; }
    .client-filter .client-filter-list__item:last-child,
    .client-filter .masonry-filter-list__item:last-child,
    .masonry-filter .client-filter-list__item:last-child,
    .masonry-filter .masonry-filter-list__item:last-child {
      margin-right: 0;
      margin-bottom: 25px; }
      .client-filter .client-filter-list__item:last-child::before,
      .client-filter .masonry-filter-list__item:last-child::before,
      .masonry-filter .client-filter-list__item:last-child::before,
      .masonry-filter .masonry-filter-list__item:last-child::before {
        display: none; }
    .client-filter .client-filter-list__item a,
    .client-filter .masonry-filter-list__item a,
    .masonry-filter .client-filter-list__item a,
    .masonry-filter .masonry-filter-list__item a {
      position: relative;
      padding-bottom: 25px;
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 1em;
      text-align: center;
      color: var(--color-header-primary); }
      .client-filter .client-filter-list__item a:hover,
      .client-filter .masonry-filter-list__item a:hover,
      .masonry-filter .client-filter-list__item a:hover,
      .masonry-filter .masonry-filter-list__item a:hover {
        color: var(--color-primary); }
      .client-filter .client-filter-list__item a::before,
      .client-filter .masonry-filter-list__item a::before,
      .masonry-filter .client-filter-list__item a::before,
      .masonry-filter .masonry-filter-list__item a::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0%;
        height: 1px;
        transition: all .3s;
        background: var(--color-primary); }
      .client-filter .client-filter-list__item a.active::before,
      .client-filter .masonry-filter-list__item a.active::before,
      .masonry-filter .client-filter-list__item a.active::before,
      .masonry-filter .masonry-filter-list__item a.active::before {
        left: 0;
        width: 100%; }
  @media (max-width: 1199px) {
    .client-filter .client-filter-list__item,
    .client-filter .masonry-filter-list__item,
    .masonry-filter .client-filter-list__item,
    .masonry-filter .masonry-filter-list__item {
      margin-right: 45px; }
      .client-filter .client-filter-list__item::before,
      .client-filter .masonry-filter-list__item::before,
      .masonry-filter .client-filter-list__item::before,
      .masonry-filter .masonry-filter-list__item::before {
        left: calc(100% + 5px);
        width: 35px; } }
  @media (max-width: 991px) {
    .client-filter .client-filter-list__item,
    .client-filter .masonry-filter-list__item,
    .masonry-filter .client-filter-list__item,
    .masonry-filter .masonry-filter-list__item {
      margin-right: 30px; }
      .client-filter .client-filter-list__item::before,
      .client-filter .masonry-filter-list__item::before,
      .masonry-filter .client-filter-list__item::before,
      .masonry-filter .masonry-filter-list__item::before {
        left: calc(100% + 5px);
        width: 20px; }
      .client-filter .client-filter-list__item a,
      .client-filter .masonry-filter-list__item a,
      .masonry-filter .client-filter-list__item a,
      .masonry-filter .masonry-filter-list__item a {
        font-size: 16px; } }
  @media (max-width: 767px) {
    .client-filter .client-filter-list,
    .client-filter .masonry-filter-list,
    .masonry-filter .client-filter-list,
    .masonry-filter .masonry-filter-list {
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center; }
    .client-filter .client-filter-list__item,
    .client-filter .masonry-filter-list__item,
    .masonry-filter .client-filter-list__item,
    .masonry-filter .masonry-filter-list__item {
      margin-right: 0;
      margin-bottom: 15px; }
      .client-filter .client-filter-list__item::before,
      .client-filter .masonry-filter-list__item::before,
      .masonry-filter .client-filter-list__item::before,
      .masonry-filter .masonry-filter-list__item::before {
        display: none; }
      .client-filter .client-filter-list__item a,
      .client-filter .masonry-filter-list__item a,
      .masonry-filter .client-filter-list__item a,
      .masonry-filter .masonry-filter-list__item a {
        padding-bottom: 10px; } }

/*===> End client-filter <===*/

/*===> Begin popup-wrap <===*/
.popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  transition: all .3s;
  visibility: hidden;
  opacity: 0; }
  .popup-wrap .popup-inside {
    z-index: 10000;
    -ms-transform: scale(0.5);
        transform: scale(0.5);
    margin-bottom: 0;
    margin-top: 0;
    opacity: 0;
    transition: all .3s; }
  .popup-wrap.show {
    opacity: 1;
    visibility: visible; }
    .popup-wrap.show .popup-inside {
      -ms-transform: scale(1);
          transform: scale(1);
      opacity: 1; }

/*===> End popup-wrap <===*/

/*===> Begin popup-content <===*/
.popup-content {
  position: relative;
  max-width: 100vw;
  width: 100%;
  margin: 120px auto;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 50px;
  padding-bottom: 20px; }
  .popup-content h3 {
    margin-bottom: 20px; }
  .popup-content .close {
    float: right;
    position: relative;
    -ms-transform: translate(100%, -50%);
        transform: translate(100%, -50%); }
  @media (max-width: 991px) {
    .popup-content .close {
      -ms-transform: translate(-10%, -50%);
          transform: translate(-10%, -50%); } }
  @media (max-width: 767px) {
    .popup-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px; } }
  @media (max-width: 480px) {
    .popup-content {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px; } }

/*===> End popup-content <===*/

.mfp-arrow-left:before {
  border-right-color: transparent;
}
.mfp-arrow-right:before {
  border-left-color: transparent;
}

/* .footer-links */
.footer-links {
  margin: 4rem 0;
}

.footer-links a {
  line-height: 3rem;
  display: inline-block;
}
.footer-links li { margin-right: 0; }
.footer-links li:not(:first-child)::before {
  content: '|';
  color: var(--color-text-gray);
  margin: 0 .78rem 0 .28rem;
}
