/* Slidey Slide */

.slidey-slide {
  position: relative; }

  .slidey-slide > .slidey-slide-image {
    -webkit-background-size: auto;
    -moz-background-size: auto;
    -o-background-size: auto;
    background-size: auto;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    will-change: transform;
    opacity: 0.8;
    position: absolute;
    transition: background-position-x 0.1s ease 0s;
    display: none; }

    .slidey-slide > .slidey-slide-image + div {
      position: relative; }
  .slidey-slide .slidey-slide-overlay {
    position:relative;
    z-index: 0; }
  .slidey-slide .slidey-slide-overlay-content {
    position: absolute;
    background-color: #fff;
    height: 800px;
    width: 100%; }

body.slidey-slide-full {
  background-color: #222;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center 0; }

.slidey-slide + section,
.slidey-slide + footer {
  position: relative;
}