/*!
 * Start Bootstrap - Creative v3.3.7+1 (http://startbootstrap.com/template-overviews/creative)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
html,
body {
  height: 100%;
}

body { background-color: black; }

hr {
  max-width: 100px;
  height: 2px;
  border-top: 1px solid white;
  border-bottom: 1px solid white; }

hr {
  border-color: #EF4035; }

@media (min-width: 992px) {
  hr {
    max-width: 150px; } }

section {
  padding: 100px 0;
  color: white;
}

@media (max-width: 767px) {
  section {
    padding: 75px 0; } }

::-webkit-input-placeholder {
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

:-moz-placeholder {
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

::-moz-placeholder {
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

:-ms-input-placeholder {
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

body {
  font-weight: 200;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

a {
  color: #EF4035;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s; }
  a:hover, a:focus {
    color: #c81b10;
    text-decoration: none; }

p {
  font-size: 18px;
  line-height: 1.5; }

p.lead {
  font-size: 24px;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase; }

@media (min-width: 768px) {
  h1 {
    font-size: 52px; }
  h2 {
    font-size: 50px; } }

#mainNav {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #222;
  padding: 10px 0;
  min-height: 68px; }
  #mainNav .navbar-toggler {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: white; }
    #mainNav .navbar-toggler:hover, #mainNav .navbar-toggler:focus {
      color: #EF4035;
      background-color: #222;
      outline: 0; }
  #mainNav .navbar-brand {
    font-size: 24px;
    text-decoration: none;
    opacity: 1;
    color: white;
    margin-left: 16px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase; }
    #mainNav .navbar-brand:after,
    .masthead-title:after {
      content:'.';
      color: #EF4035;
      padding-left: .125rem; }
    #mainNav .navbar-brand img {
      height: 40px; }
  #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link {
    padding: 15px;
    color: white; }
    #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link:hover, #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link:focus {
      color: rgba(255, 255, 255, 0.5); }
    #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link.active, #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link.active:hover, #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link.active:focus {
      color: #EF4035; }
  @media (min-width: 992px) {
    #mainNav {
      padding: 30px 0;
      -webkit-transition: padding .5s;
      -moz-transition: padding .5s;
      transition: padding .5s;
      border: 0;
      background-color: transparent; }
      #mainNav.navbar-shrink {
        padding: 5px 0;
        background-color: rgba(0, 0, 0, 0.9); }
      #mainNav .navbar-brand img {
        height: 50px; }
      #mainNav .navbar-collapse .navbar-nav > .nav-item {
        margin-right: 5px; }
        #mainNav .navbar-collapse .navbar-nav > .nav-item:last-child {
          margin-right: 0; }
        #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link.active, #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link.active:hover, #mainNav .navbar-collapse .navbar-nav > .nav-item > .nav-link.active:focus {
          color: #EF4035;
          border-radius: 0;
          /* background-color: white; */
          border-radius: 5px; } }

.team-carousel .item {
  position: relative;
  margin-right: 15px;
  margin-left: 15px; }
  .team-carousel .item .overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background-color: #222; }
  .team-carousel .item .team-caption {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: auto;
    padding: 15px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.7); }
    .team-carousel .item .team-caption h3 {
      margin: 0; }
    .team-carousel .item .team-caption hr {
      max-width: 50px;
      margin: 5px auto; }
    .team-carousel .item .team-caption p {
      margin: 0; }
    .team-carousel .item .team-caption .list-team-social {
      margin-top: 5px; }
      .team-carousel .item .team-caption .list-team-social li a {
        color: rgba(255, 255, 255, 0.7); }
        .team-carousel .item .team-caption .list-team-social li a:hover, .team-carousel .item .team-caption .list-team-social li a:focus {
          color: white; }

.call-to-action {
  padding: 100px 0;
  text-align: center;
  color: white;
  background-color: #111;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll; }
  @media (min-width: 1024px) {
    .call-to-action {
      background-attachment: fixed; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .call-to-action {
      background-attachment: scroll; } }
  .call-to-action .quote {
    font-size: 30px;
    font-weight: 300;
    line-height: 32px;
    display: block; }
  @media (min-width: 768px) {
    .call-to-action {
      padding: 150px 0; }
      .call-to-action .quote {
        font-size: 36px;
        line-height: 38px; } }
  @media (min-width: 992px) {
    .call-to-action {
      padding: 300px 0; }
      .call-to-action .quote {
        font-size: 40px;
        line-height: 42px; } }
  .call-to-action .quote .small {
    display: block;
    color: rgba(255, 255, 255, 0.7);
  }
  .call-to-action .quote .small:before {
    content: '\2014 \00A0';
  }

.services .media {
  margin-bottom: 30px; }
  .services .media i.fa,
  .services .media i.fab {
    font-size: 30px;
    line-height: 70px;
    width: 75px;
    height: 75px;
    text-align: center;
    border: 3px solid #222; }
  .services .media .media-body {
    min-width: none;
    padding-left: 15px; }
    .services .media .media-body h3 {
      margin-bottom: 15px; }
    .services .media .media-body ul {
      padding: 0;
      list-style: inside; }
      .services .media .media-body ul li {
        margin-bottom: 10px; }

@media (min-width: 992px) {
  .services .media {
    margin-bottom: 0; } }

.carousel {
  color: white;
  background-color: #000; }
  .portfolio-carousel .item {
    padding-top: 75px;
    padding-right: 30px;
    padding-bottom: 75px;
    padding-left: 30px;
    text-align: center;
    background-color: #222;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center; }
    .portfolio-carousel .item .overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .7;
      background-color: #000; }
    .featured-client.container-fluid {
      z-index: 1000; }
    .featured-client .fa-calendar:before {
      content: "\f008"; }
    .featured-client .client-details img.client-mugshot {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 0rem;
      right: 1rem; }
      @media (min-width: 992px) {
        .featured-client .client-details img.client-mugshot {
          width: 64px;
          height: 64px; } }
    .featured-client .client-details .client-name {
      font-size: 24px;
      display: block;
      margin-bottom: 5px;
      font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      margin-right: 32px; }
      @media (min-width: 992px) {
        .featured-client .client-details .client-name {
          margin-right: 64px; } }
      .featured-client .client-details .client-name:before {
        content: 'Featured:  ';
        color: rgba(255,255,255,.5);
        font-size: 24px;
      }
    .featured-client .client-details .client-description {
      font-size: 18px;
      line-height: 18px;
      display: block; }
    .featured-client .device-container {
      padding: 25px; }
  @media (min-width: 768px) {
    .featured-client {
      padding-right: 80px;
      padding-bottom: 125px;
      padding-left: 80px;
      text-align: left; }
      .featured-client .client-details img.client-logo {
        margin: 0 auto 25px 0; }
      .featured-client .client-details .client-name {
        font-size: 32px;
        display: block; }
      .featured-client .client-details .client-description {
        font-size: 20px;
        line-height: 20px; }
      .featured-client hr {
        margin: 20px auto 20px 0; } }

.portfolio-gallery .item {
  padding: 15px; }

.portfolio-modal {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll; }
  @media (min-width: 1024px) {
    .portfolio-modal {
      background-attachment: fixed; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .portfolio-modal {
      background-attachment: scroll; } }
  .portfolio-modal .modal-backdrop {
    display: none; }
  .portfolio-modal .modal-dialog {
    max-width: none; }
    .portfolio-modal .modal-dialog .modal-content {
      min-height: 100%;
      padding: 25px 0;
      text-align: center;
      color: white;
      border: none;
      border-radius: 0;
      background: transparent;
      background-clip: border-box;
      -webkit-box-shadow: none;
      box-shadow: none; }
      .portfolio-modal .modal-dialog .modal-content h2 {
        font-size: 46px;
        margin: 0; }
      .portfolio-modal .modal-dialog .modal-content .item-details {
        margin: 30px 0; }
    .portfolio-modal .modal-dialog .close-modal {
      position: absolute;
      top: 25px;
      right: 25px;
      width: 75px;
      height: 75px;
      cursor: pointer;
      background-color: transparent;
      z-index: 1050; }
      .portfolio-modal .modal-dialog .close-modal:hover {
        opacity: .3; }
      .portfolio-modal .modal-dialog .close-modal .lr {
        /* Safari and Chrome */
        z-index: 1051;
        width: 1px;
        height: 75px;
        margin-left: 35px;
        /* IE 9 */
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        background-color: white; }
        .portfolio-modal .modal-dialog .close-modal .lr .rl {
          /* Safari and Chrome */
          z-index: 1052;
          width: 1px;
          height: 75px;
          /* IE 9 */
          -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
          background-color: white; }

.mockup-carousel .item {
  margin: 0;
  padding: 0; }
  .mockup-carousel .item img {
    margin: 0; }

.portfolio-grid .grid-item {
  float: left;
  overflow: hidden;
  width: 48%;
  margin: 1%; }
  .portfolio-grid .grid-item .portfolio-wrapper {
    position: relative !important;
    overflow: hidden;
    cursor: pointer;
    background: #222; }
    .portfolio-grid .grid-item .portfolio-wrapper img,
    .portfolio-grid .grid-item .portfolio-wrapper .client-title {
      max-width: 100%;
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-position: center; }
      .portfolio-grid .grid-item .portfolio-wrapper:hover img,
      .portfolio-grid .grid-item .portfolio-wrapper:hover .client-title {
        -webkit-filter: grayscale(1);
        filter: grayscale(1); }
      .portfolio-grid .grid-item .portfolio-wrapper .client-title .overlay {
        padding: 30px 0;
        background: rgba(0, 0, 0, 0.51);
        min-height: 220px;
        position: relative; }
    .portfolio-grid .grid-item .portfolio-wrapper .caption {
      position: absolute;
      bottom: -140px;
      width: 100%;
      height: 220px;
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s; }
      .portfolio-grid .grid-item .portfolio-wrapper .caption .caption-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: /*#EF4035*/ rgba(0, 0, 0, 0.33);
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s; }
        .portfolio-grid .grid-item .portfolio-wrapper:hover .caption .caption-bg {
          background: rgba(0, 0, 0, 0.81); }
      .portfolio-grid .grid-item .portfolio-wrapper .caption .caption-text {
        position: relative;
        z-index: 500;
        padding: 0px 8px;
        color: white; }
        .portfolio-grid .grid-item .portfolio-wrapper .caption .caption-text a.text-title {
          color: white; }
        .portfolio-grid .grid-item .portfolio-wrapper .caption .caption-text .text-category {
          font-size: 12px;
          display: block; }
  .portfolio-grid .grid-item:hover .portfolio-wrapper .caption {
    bottom: 0; }

@media (min-width: 768px) {
  .portfolio-grid .grid-item {
    width: 31%;
    margin: 1%; } }

.testimonials {
  background-color: #111;
  text-align: center; }
  .block-testimonial .testimonial-img {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 10px; }
    .block-testimonial .testimonial-img img {
      vertical-align: baseline; }
  .block-testimonial .testimonial-author {
    display: inline-block; }
    .block-testimonial .testimonial-author .name {
      font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-weight: 700;
      text-transform: uppercase; }
    .block-testimonial .testimonial-author hr.colored {
      max-width: 50px;
      margin: 3px auto; }
    .block-testimonial .testimonial-author p {
      margin: 0; }
    .block-testimonial .testimonial-author .stars {
      color: #f1c40f; }
  @media (min-width: 992px) {
    .block-testimonial {
      padding: 0 100px; } }

.pricing {
  color: white;
  background-color: #222;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll; }
  @media (min-width: 1024px) {
    .pricing {
      background-attachment: fixed; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .pricing {
      background-attachment: scroll; } }
  .pricing .pricing-item {
    z-index: 1;
    margin-bottom: 30px;
    padding: 30px;
    text-align: center;
    color: #222;
    background: white;
    -webkit-box-shadow: 0 0 30px 5px rgba(34, 34, 34, 0.75);
    -moz-box-shadow: 0 0 30px 5px rgba(34, 34, 34, 0.75);
    box-shadow: 0 0 30px 5px rgba(34, 34, 34, 0.75); }
    .pricing .pricing-item h3 {
      margin: 0; }
    .pricing .pricing-item hr {
      max-width: 100px; }
    .pricing .pricing-item .price {
      margin-bottom: 20px; }
      .pricing .pricing-item .price .number {
        font-size: 50px;
        font-weight: 700; }
        .pricing .pricing-item .price .number sup {
          font-size: 30px; }
    .pricing .pricing-item .list-group-item {
      border-radius: 0; }
  @media (min-width: 992px) {
    .pricing .pricing-item {
      margin-bottom: 0; }
    .pricing .pricing-item.featured-first {
      margin-top: 15px;
      margin-right: -15px; }
    .pricing .pricing-item.featured {
      position: absolute;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 45px 30px; }
    .pricing .pricing-item.featured-last {
      margin-top: 15px;
      margin-left: -15px; } }

.signup-form .input-group .form-control {
  border: 0;
  border-color: white;
  border-radius: 0; }

.signup-form .input-group .input-group-btn .btn-primary {
  border: 0;
  border-radius: 0;
  background-color: #EF4035;
  font-size: 1rem; }
  .signup-form .input-group .input-group-btn .btn-primary:hover {
    color: white;
    background-color: #df1e12; }

.floating-label-form-group {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #e1e1e1; }
  .floating-label-form-group input,
  .floating-label-form-group textarea {
    z-index: 1;
    position: relative;
    padding-right: 0;
    padding-left: 0;
    border: none;
    border-radius: 0;
    font-size: 1.5em;
    background: none;
    box-shadow: none !important;
    resize: none; }
  .floating-label-form-group label {
    display: block;
    z-index: 0;
    position: relative;
    top: 2em;
    margin: 0;
    font-size: 0.85em;
    line-height: 1.764705882em;
    vertical-align: middle;
    vertical-align: baseline;
    opacity: 0;
    -webkit-transition: top 0.5s ease,opacity 0.5s ease;
    -moz-transition: top 0.5s ease,opacity 0.5s ease;
    -ms-transition: top 0.5s ease,opacity 0.5s ease;
    transition: top 0.5s ease,opacity 0.5s ease; }
  .floating-label-form-group::not(:first-child) {
    padding-left: 14px;
    border-left: 1px solid #e1e1e1; }

.floating-label-form-group-with-value label {
  top: 0;
  opacity: 1; }

.floating-label-form-group-with-focus label {
  color: #EF4035; }

form .row:first-child .floating-label-form-group {
  border-top: 1px solid #e1e1e1; }

footer.footer {
  padding: 50px 0 20px;
  color: rgba(255, 255, 255, 0.7);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll; }
  @media (min-width: 1024px) {
    footer.footer {
      background-attachment: fixed; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    footer.footer {
      background-attachment: scroll; } }
  footer.footer a {
    color: rgba(255, 255, 255, 0.7); }
    footer.footer a:hover {
      color: white; }
  footer.footer p {
    font-size: 22px; }
  footer.footer .footer-contact-details {
    margin: 75px 0; }
  footer.footer .social-media-links {
    margin-bottom: 75px; }
  footer.footer .copyright {
    font-size: 1rem; }

.btn {
  font-weight: 700;
  padding: 12px 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0; }

.btn-primary {
  border: 1px solid white;
  background-color: transparent; }
  .btn-primary:hover, .btn-primary:focus {
    color: #EF4035;
    border-color: #EF4035;
    background-color: rgba(0, 0, 0, 0.3); }

.btn-secondary {
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);
  background-color: transparent; }
  .btn-secondary:hover,
  .btn-secondary:focus,
  .btn-secondary.mixitup-control-active {
    color: white;
    border-color: #EF4035;
    background-color: #EF4035; }

button:hover {
  cursor: pointer; }

.btn-full-width {
  font-size: 26px;
  font-weight: 200;
  padding: 50px 0;
  letter-spacing: normal;
  text-transform: none;
  color: white;
  border: 0;
  border-radius: 0;
  background-color: #222; }
  .btn-full-width:hover {
    color: white;
    background-color: #EF4035; }
  .btn-full-width:focus {
    color: white; }

.text-primary {
  color: #EF4035 !important; }

a.text-primary {
  color: #EF4035 !important; }
  a.text-primary:hover, a.text-primary:focus {
    color: #c81b10 !important; }

.bg-inverse {
  background-color: #222 !important; }

.owl-carousel.owl-theme .owl-nav {
  margin-top: 0; }
  .owl-carousel.owl-theme .owl-nav .owl-prev,
  .owl-carousel.owl-theme .owl-nav .owl-next {
    opacity: .5;
    font-size: 30px !important;
    line-height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 60px;
    margin: auto 0 !important;
    text-align: center;
    color: white !important;
    border-radius: 0 !important;
    background-color: #EF4035 !important;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s; }
    @media (min-width: 768px) {
      .owl-carousel.owl-theme .owl-nav .owl-prev,
      .owl-carousel.owl-theme .owl-nav .owl-next {
        font-size: 50px !important;
        line-height: 90px;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 70px;
        height: 100px; }}
    .owl-carousel.owl-theme .owl-nav .owl-prev:hover,
    .owl-carousel.owl-theme .owl-nav .owl-next:hover {
      opacity: 1; }
  .owl-carousel.owl-theme .owl-nav .owl-prev {
    left: 15px; }
  .owl-carousel.owl-theme .owl-nav .owl-next {
    right: 15px; }
  .owl-carousel.owl-theme .owl-nav .owl-page span {
    background: rgba(255, 255, 255, 0.5); }

.owl-carousel.owl-theme.portfolio-carousel .owl-nav .owl-prev {
  left: 0; }

.owl-carousel.owl-theme.portfolio-carousel .owl-nav .owl-next {
  right: 0; }

.owl-carousel.owl-theme.portfolio-carousel .owl-dots {
  position: relative;
  top: -48px;
}

section.text-lg p {
  font-size: 24px;
}
section.about .block-two_column .rich-text p {
  text-align: left!important;
}

.portfolio-carousel .table th, .portfolio-carousel .table td {
  border-top: none;
  border-bottom: none;
}

.portfolio-carousel .owl-controls { padding-top: 25px; }

.client-details .project-list .project-item {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #202224;
    text-align: left;
}

.client-details .project-list .project-item.has-date > .title,
.client-details .project-list .project-item.has-date > .meta-list {
    padding-left: 60px;
}

.client-details .project-list .date-label {
  background: #202224;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 45px;
  height: 60px;
  padding-top: 10px;
  position: absolute;
  left: 0;
  top: 15px;
}
.client-details .project-list .date-label .fa,
.client-details .project-list .date-label .fab {
  font-size: 20px;
}
.client-details .project-list .date-label .year {
  font-size: 14px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.7);
}

.client-details .project-list .title {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700;
}
.client-details .project-list .title a {
  color: #fff;
}
.client-details .project-list .meta-list {
  color: #ffe1d8;
}
.client-details .project-list .meta-list a {
  color: #ffe1d8;
}
.client-details .project-list .meta-list .fa,
.client-details .project-list .meta-list .fab {
  margin-right: 5px;
  color: #EF4035;
  font-size: 16px;
}
.client-details .actions {
  position: absolute;
  right: 1rem;
  top: 15px;
}
.client-details > .actions {
  top: 5rem;
}
.client-details img.client-mugshot {
  width: 32px;
  height: 32px;
}
@media (min-width: 992px) {
  .client-details img.client-mugshot {
    width: 48px;
    height: 48px; }
}
.client-details .actions .btn {
  margin-right: 5px;
}
.client-details .actions .btn:last-child {
  margin-right: 0;
}
.client-details .project-list .info-extra {
  color: #4f545e;
  font-size: 16px;
}

.portfolio-grid .client-details .client-name {
  font-size: 16px;
  display: block;
  margin-bottom: 1px;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase; }
.portfolio-grid .grid-item .client-details .client-description {
  font-size: 14px;
  display: block; }
  @media (min-width: 768px) {
    .portfolio-grid .mix-disabled {
      padding-top: 125px;
      padding-right: 80px;
      padding-bottom: 125px;
      padding-left: 80px;
      text-align: left; }
      .portfolio-grid .grid-item .client-details img.client-logo {
        margin: 0 auto 25px 0; }
      .portfolio-grid .grid-item .client-details .client-name {
        font-size: 20px;
        display: block; }
      .portfolio-grid .grid-item .client-details .client-description {
        font-size: 16px; }
      .portfolio-grid .grid-item hr.colored {
        margin: 20px auto 20px 0; } }
.portfolio-grid .grid-item .client-details .project-item {
  padding-top: 10px;
  padding-bottom: 0px; }
  .portfolio-grid .grid-item .client-details .project-item .title {
    font-size: 16px; }
  .portfolio-grid .grid-item .client-details .project-item .meta-list {
    font-size: 12px; }
  .portfolio-grid .grid-item .client-details .project-list .date-label {
    top: 10px;
    height: 54px;
    padding-top: 6px; }

.btn-default {
    border: 1px solid #EF4035;
    color: #EF4035;
    background-color: transparent;
    font-weight: 400;
    letter-spacing: 0px;
}

.btn-default:hover,
.btn-default:focus {
    border: 1px solid #EF4035;
    outline: 0;
    color: #000;
    background-color: #EF4035;
}
.btn-xs {
  padding: .18rem .35rem;
  font-size: .8rem;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #EF4035; }

.quote {
  margin: 15px 0; }
  .quote .quotation-mark {
    display: inline-block;
    color: rgba(255, 255, 255, 0.33);
    position: relative; }
  .quote .quotation-mark.fa-quote-left {
    margin: 0 15px 0 0; top: -10px; }
  .quote .quotation-mark.fa-quote-right {
    margin: 0 0 0 15px; bottom: -10px; }

.posts .post-item {
  font-size: 1.1rem;
  position: relative;
  border-bottom: 1px solid #202224; }
  .posts .post-item .post-date {
    font-size: .9rem;
    color: #ffe1d8; }
  .posts .post-item a.tag {
    font-size: 1rem;
    margin-right: .25rem;
    color: #ffe1d8; }
  .posts .post-item a.tag:hover {
    font-size: 1rem;
    color: #ffe1d8; }
  .posts .post-item .post-text {
    white-space: pre-wrap; }
  .posts .post-item .list-inline {
    margin-top: 1em; }
    .posts .post-item .list-inline > li > a {
      color: rgba(255, 255, 255, 0.7); margin: 0 .3em; }
    .posts .post-item .list-inline > li > a:hover {
      color: rgba(255, 255, 255, 1.0); }


.wow.fadeIn {
  visibility: hidden; }
  body.wow-disabled.wow.fadeIn,
  body.wow-disabled .wow.fadeIn {
    visibility: visible; }

body > .navbar + .tns-outer:before {
  position: absolute;
  display: block;
  width: 100%;
  color: rgba(255, 255, 255, .525);
  font-weight: 700;
  text-transform: uppercase;
  z-index: 1;
  top: 80px;
  padding-left: 1rem; }
@media (min-width: 992px) {
  body > .navbar + .tns-outer:before {
    text-align: center;
    top: 112px;
    font-size: 50px;
    color: white;
    padding-left: 0; } }

body > .navbar + .tns-outer .slide-container {
  padding-top: 112px; }

body > .navbar + .tns-outer .carousel.height-100vh .item > .slide-container > .slide-content {
  vertical-align: top; }

@media (min-width: 992px) {
  body > .navbar + .tns-outer .slide-container {
    padding-top: 240px; } }

body > .navbar + .tns-outer .tns-nav {
  box-shadow: 0px -40px 50px 80px black; }


.tns-outer .tns-controls button[data-controls="prev"],
.tns-outer .tns-controls button[data-controls="next"] {
  background-color: rgba(239, 64, 53, .55) !important;
  color: #fff!important; }
  .tns-outer .tns-controls button[data-controls="prev"]:hover,
  .tns-outer .tns-controls button[data-controls="next"]:hover {
    background-color: #EF4035 !important; }
.tns-outer .tns-nav button {
  background-color: #FFFFFF;
  opacity: .75; }
  .tns-outer .tns-nav button:hover {
    opacity: 1; }
  .tns-outer .tns-nav button:focus {
    outline: 0; }
  .tns-outer .tns-nav button.tns-nav-active {
    background-color: #EF4035; }
